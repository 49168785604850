import { forwardRef, useEffect, useRef, memo } from 'react';
import { usePagination, useSearchBox } from 'react-instantsearch';
import { AutocompleteOptions } from '@algolia/autocomplete-js';
import { BaseItem } from '@algolia/autocomplete-core';
import { SearchInput } from '@components/search';
import { AutocompleteStateProps } from '@ts/algolia';
import styles from './AutoComplete.module.scss';
import '@algolia/autocomplete-theme-classic';


type AutocompleteProps = Partial<AutocompleteOptions<BaseItem>> & {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	autocomplete: any;
	autocompleteState: AutocompleteStateProps;
	queryHook: (query: string, hook: (value: string) => void) => void;
	story?: boolean;
};

const AutoComplete = forwardRef<HTMLInputElement, AutocompleteProps>(({ autocomplete, autocompleteState, queryHook, story }, ref) => {
	const autocompleteContainer = useRef<HTMLDivElement>(null);
	const { getInputProps, setIsOpen } = autocomplete
	const { isOpen, query } = autocompleteState

	const { refine: setQuery } = useSearchBox();
	const { refine: setPage } = usePagination();

	useEffect(() => {
		setQuery(query);
		setPage(0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [autocompleteState]);


	return (
		<div className={`autocompleteContainer ${styles.autocompleteContainer}`} ref={autocompleteContainer}>
			<div className='aa-Autocomplete' {...autocomplete.getRootProps({})}>
				<SearchInput queryHook={queryHook} story={story} inputProps={getInputProps({})} setQuery={autocomplete.setQuery} setOpen={setIsOpen} isOpen={isOpen} ref={ref} />
			</div>
		</div>
	);
});

AutoComplete.displayName = 'AutoComplete';

export default memo(AutoComplete);
