import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

type CartIconProps = {
	className?: string;
	label: string;
};

const CartIcon = ({ className = undefined, label }: CartIconProps) => {
	return (
		<AccessibleIcon.Root label={label}>
			<svg {...(className && { className })} width='21' height='17' viewBox='0 0 21 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M9 15.5C9 15.2239 9.22386 15 9.5 15C9.77614 15 10 15.2239 10 15.5C10 15.7761 9.77614 16 9.5 16C9.22386 16 9 15.7761 9 15.5Z'
					fill='black'
				/>
				<path
					d='M16 15.5C16 15.2239 16.2239 15 16.5 15C16.7761 15 17 15.2239 17 15.5C17 15.7761 16.7761 16 16.5 16C16.2239 16 16 15.7761 16 15.5Z'
					fill='black'
				/>
				<path
					d='M9 15.5C9 15.2239 9.22386 15 9.5 15C9.77614 15 10 15.2239 10 15.5C10 15.7761 9.77614 16 9.5 16C9.22386 16 9 15.7761 9 15.5Z'
					stroke='black'
					strokeWidth='1.5'
				/>
				<path
					d='M16 15.5C16 15.2239 16.2239 15 16.5 15C16.7761 15 17 15.2239 17 15.5C17 15.7761 16.7761 16 16.5 16C16.2239 16 16 15.7761 16 15.5Z'
					stroke='black'
					strokeWidth='1.5'
				/>
				<path
					d='M4.96552 3.36364H20L18.1793 11.2256C18.0743 11.679 17.6705 12 17.2051 12H8L4.96552 3.36364Z'
					fill='transparent'
				/>
				<path
					d='M20 3.36364L20.7307 3.53284C20.7823 3.31008 20.7294 3.07595 20.5871 2.89695C20.4448 2.71795 20.2287 2.61364 20 2.61364V3.36364ZM4 1L4.69431 0.716384C4.57909 0.434325 4.30468 0.25 4 0.25V1ZM1 0.25C0.585786 0.25 0.25 0.585786 0.25 1C0.25 1.41421 0.585786 1.75 1 1.75V0.25ZM8 12L7.29241 12.2486C7.39795 12.549 7.68163 12.75 8 12.75V12ZM18.1793 11.2256L17.4487 11.0564L18.1793 11.2256ZM4.96552 4.11364H20V2.61364H4.96552V4.11364ZM19.2693 3.19443L17.4487 11.0564L18.91 11.3948L20.7307 3.53284L19.2693 3.19443ZM17.2051 11.25H8V12.75H17.2051V11.25ZM8.70759 11.7514L5.67311 3.11502L4.25792 3.61226L7.29241 12.2486L8.70759 11.7514ZM5.65982 3.08002L4.69431 0.716384L3.30569 1.28362L4.27121 3.64725L5.65982 3.08002ZM4 0.25H1V1.75H4V0.25ZM17.4487 11.0564C17.4224 11.1697 17.3215 11.25 17.2051 11.25V12.75C18.0195 12.75 18.7263 12.1882 18.91 11.3948L17.4487 11.0564Z'
					fill='black'
				/>
			</svg>
		</AccessibleIcon.Root>
	);
};

export default CartIcon;
