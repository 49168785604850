import NextHead from 'next/head';
import { FC, Fragment, ReactNode } from 'react';
import { ArticleJsonLdProps, ProductJsonLdProps, addArticleJsonLd, addProductJsonLd } from '@utils/seo';
import { Image } from '@ts/shopify-storefront-api';
import seo from '../../seo_meta.json' assert { type: 'json' };

const storeUrl = process.env.NEXT_PUBLIC_STORE_URL || process.env.NEXT_PUBLIC_VERCEL_URL || null;

interface OgImage {
	url?: string;
	width?: string;
	height?: string;
	alt?: string;
}

interface SEOProps {
	title?: string;
	description?: string;
	article?: ArticleJsonLdProps;
	product?: ProductJsonLdProps;
	openGraph?: {
		title?: string;
		type?: string;
		locale?: string;
		description?: string;
		site_name?: string;
		url?: string;
		images?: OgImage[];
	};
	images?: Image[];
	children?: ReactNode;
	noindex?: boolean;
	nofollow?: boolean;
}

const ogImage = ({ url, width, height, alt }: OgImage, index: number) => {
	// generate full URL for OG image url with store base URL
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const imgUrl = storeUrl ? new URL(url!, storeUrl).toString() : url;
	return (
		<Fragment key={`og:image:${index}`}>
			<meta key={`og:image:url:${index}`} property='og:image' content={imgUrl} />
			<meta key={`og:image:width:${index}`} property='og:image:width' content={width} />
			<meta key={`og:image:height:${index}`} property='og:image:height' content={height} />
			<meta key={`og:image:alt:${index}`} property='og:image:alt' content={alt ?? 'Pair Eyewear'} />
		</Fragment>
	);
};

const SEO: FC<SEOProps> = ({
	article,
	title,
	description,
	product,
	openGraph,
	children,
	noindex = false,
	nofollow = false,
}) => {
	const robots = `${noindex ? 'noindex' : 'index'},${nofollow ? 'nofollow' : 'follow'}`;

	return (
		<NextHead>
			{/* SEO Metadata - Google */}
			<title key='title'>{product?.name || title || seo.title}</title>
			<meta name='description' content={product?.description || description || seo.description} key='desc' />

			{/* Structured Data */}
			{product && (
				<script
					id='productJsonLd'
					type='application/ld+json'
					dangerouslySetInnerHTML={addProductJsonLd(product)}
					key='product-jsonld'
				/>
			)}
			{article && (
				<script
					id='articleJsonLd'
					type='application/ld+json'
					dangerouslySetInnerHTML={addArticleJsonLd(article)}
					key='article-jsonld'
				/>
			)}


			{/* OpenGraph */}
			<meta key='og:type' property='og:type' content={openGraph?.type ?? seo.openGraph.type} />
			<meta
				key='og:title'
				property='og:title'
				content={openGraph?.title ?? seo.openGraph.title ?? title ?? product?.name ?? seo.title} />
			<meta
				key='og:description'
				property='og:description'
				content={
					openGraph?.description ?? seo.openGraph.description ?? description ?? product?.description ?? seo.description
				} />
			<meta key='og:site_name' property='og:site_name' content={openGraph?.site_name ?? seo.openGraph.site_name} />
			<meta key='og:url' property='og:url' content={openGraph?.url ?? seo.openGraph.url} />
			{openGraph?.locale && <meta key='og:locale' property='og:locale' content={openGraph.locale} />}
			{openGraph?.images?.length
				? openGraph.images.map((img, index) => ogImage(img, index))
				: ogImage(seo.openGraph.images[0], 0)}

			{/* Twitter */}
			{seo.twitter.cardType && <meta key='twitter:card' name='twitter:card' content={seo.twitter.cardType} />}
			{seo.twitter.site && <meta key='twitter:site' name='twitter:site' content={seo.twitter.site} />}
			{seo.twitter.handle && <meta key='twitter:creator' name='twitter:creator' content={seo.twitter.handle} />}

			{/* Robots */}
			<meta key='robots' name='robots' content={robots} />
			<meta key='googlebot' name='googlebot' content={robots} />

			{children}
		</NextHead>
	);
};

export default SEO;
