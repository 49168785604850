import { Dict } from "@ts/general";

/* eslint-disable @typescript-eslint/no-explicit-any */
const cleanGraphqlResponse = <T>(input: Dict): T => {
	if (!input) return null;
	const output: any = {};
	const isObject = (obj: any) => {
		return obj !== null && typeof obj === 'object' && !Array.isArray(obj);
	};

	Object.keys(input).forEach(key => {
		if (input[key] && input[key].edges) {
			output[key] = input[key].edges.map((edge: { node: Dict }) => cleanGraphqlResponse(edge.node));
		} else if (isObject(input[key])) {
			output[key] = cleanGraphqlResponse(input[key]);
		} else if (key !== '__typename') {
			output[key] = input[key];
		}
	});

	return output;
};

export default cleanGraphqlResponse;
