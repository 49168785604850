import { NormalizedProduct, NormalizedVariant } from '@ts/product';
import { isVariantAvailableForSale } from '@utils/shopify';
import { IMAGE_PLACEHOLDER, LOCALE_DICT } from '@constants';
import { Favorite, PomsImage, Variant } from '@ts/poms';
import { normalizeProductTitle } from './normalizers';

function getImageForVariant(favorite) {
	const variantImageId = favorite.variant.image_id;
	if (!variantImageId) return convertPomsImage(favorite.product.images[0]);

	const foundImg = favorite.product.images.find(image => image.id == variantImageId);
	return foundImg
		? convertPomsImage(foundImg)
		: {
			altText: '',
			height: 100,
			width: 100,
			url: IMAGE_PLACEHOLDER,
		};
}

function convertPomsImage(i: PomsImage) {
	return {
		altText: i.alt,
		height: i.height,
		width: i.width,
		url: i.src,
	};
}

export const normalizePomsFavorites = ({
	locale = 'en-US',
	fav,
}: {
	locale?: string;
	fav: Favorite;
}): {
	id: number;
	product: NormalizedProduct;
	variant: Variant;
	available: boolean;
} => {
	// Converts POMS response to GraphQL schema expected by TopsProductCard component
	return {
		...fav,
		product: {
			...fav.product,
			type: fav.product.product_type,
			id: fav.product.admin_graphql_api_id,
			name: normalizeProductTitle(fav.product.title, fav.product.product_type),
			tags: fav.product.tags.split(', '),
			images: fav.product.images.map(img => convertPomsImage(img)),
			options: fav.product.options.map(option => ({
				id: `${option.id}`,
				name: option.name,
				values: option.values,
			})),
			slug: fav.product.handle,
			path: null,
			availableForSale: null,
			price: null,
			totalInventory: null,
			variants: [
				{
					...fav.variant,
					id: fav.variant.admin_graphql_api_id,
					name: fav.product.title,
					option: fav.variant.title,
					type: fav.product.product_type,
					handle: fav.product.handle,
					image: getImageForVariant(fav),
					price: {
						amount: fav.variant.price,
						currencyCode: LOCALE_DICT[locale].currencyCode,
					},
					product: {
						id: fav.product.admin_graphql_api_id,
						handle: fav.product.handle,
						name: fav.product.title,
						type: fav.product.product_type,
						tags: fav.product.tags.split(', '),
					},
					availableForSale: isVariantAvailableForSale(fav.variant),
					requiresShipping: fav.variant.requires_shipping,
					metafields: {},
				},
			],
		},
	};
};

export const updateFavoritePrice = (favorite: Favorite, variants: NormalizedVariant[]) => {
	const variant = variants.find(v => v?.id === favorite?.variant?.admin_graphql_api_id);
	if (!variant) return favorite;

	return {
		...favorite,
		variant: {
			...favorite.variant,
			price: variant.price.amount,
		},
	};
};
