import { productVariantFragment } from "@services/shopify/fragments/variant";
import { cartErrorFragment } from "@services/shopify/fragments/cart";

const cartLinesAddMutation = /* GraphQL */ `
	mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
		cartLinesAdd(cartId: $cartId, lines: $lines) {
			cart {
				id
				checkoutUrl
				lines(first: 100) {
					edges {
						node {
							id
							quantity
							merchandise {
								...productVariantFragment
							}
							attributes {
								key
								value
							}
							cost {
								subtotalAmount {
									amount
									currencyCode
								}
								totalAmount {
									amount
									currencyCode
								}
							}
						}
					}
				}
				totalQuantity
			}
			userErrors {
				...cartErrorFragment
			}
		}
	}

	${productVariantFragment}
	${cartErrorFragment}
`;

export { cartLinesAddMutation };
