import { createContext, useContext, useState } from 'react';

type AllTopsDrawerProviderProps = {
	children: JSX.Element;
};

type AllTopsDrawerContextProps = {
	isAllTopsDrawerOpen: boolean,
	setIsAllTopsDrawerOpen: (status: boolean) => void
};

const AllTopsDrawerContext = createContext<AllTopsDrawerContextProps>(null);

export function AllTopsDrawerProvider({ children }: AllTopsDrawerProviderProps) {
	const [isAllTopsDrawerOpen, setIsAllTopsDrawerOpen] = useState(false);


	return (
		<AllTopsDrawerContext.Provider
			value={{
				isAllTopsDrawerOpen,
				setIsAllTopsDrawerOpen,
			}}
		>
			{children}
		</AllTopsDrawerContext.Provider>
	);
}

export function useAllTopsDrawerContext() {
	return useContext(AllTopsDrawerContext);
}
