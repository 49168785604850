import { useState, useMemo } from 'react';
import { createAutocomplete, AutocompleteSource, BaseItem } from '@algolia/autocomplete-core';
import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia';
import { algoliaClient } from '@services/algolia/client';
import { useAutocompleteProps } from '@ts/algolia';

const useAutocomplete = (initialQuery: string): useAutocompleteProps => {
	const [autocompleteState, setAutocompleteState] = useState({ query: initialQuery });

	const autocomplete = useMemo(
		() =>
			createAutocomplete({
				onStateChange({ state }) {
					setAutocompleteState(state);
				},
				onReset() {
					setAutocompleteState({ query: "" });
				},
				onSubmit({ state }) {
					setAutocompleteState({ query: state.query });
				},
				getSources() {
					const sources: AutocompleteSource<BaseItem>[] = [
						{
							sourceId: 'products',
							getItemInputValue({ item }: { item: BaseItem }): string {
								return item.query as string;
							},
							getItems({ query }) {
								return getAlgoliaResults({
									searchClient: algoliaClient,
									queries: [
										{
											indexName: 'shopify_products_query_suggestions',
											query,
											params: {
												hitsPerPage: 6,
												highlightPreTag: '<mark>',
												highlightPostTag: '</mark>',
											},
										},
									],
								});
							},
						},
					];
					return sources;
				},
				placeholder: 'Search',
			}),
		[]
	);

	return { autocomplete, autocompleteState };
}

export default useAutocomplete;
