import { memo } from 'react';
import { PopularSearches } from '@components/search';
import { Paragraph, Title } from '@components/typography';
import { FAQ_URL } from '@constants';
import styles from './NoResults.module.scss';

const NoResults = ({ query, handleSearch }) => (
	<>
		<div className={styles.container}>
			<Title style={{ marginBottom: '1.6rem' }}>
				Looks like we don&#39;t have any results for{' '}
				<span>
					<wbr />
					<q>{query}</q>
				</span>
			</Title>

			<Title style={{ marginBottom: '0.8rem' }}>Search Tips</Title>
			<ul>
				<li>
					<Paragraph>Check your search for typos</Paragraph>
				</li>
				<li>
					<Paragraph>Use more generic search terms</Paragraph>
				</li>
				<li>
					<Paragraph>The product you&apos;re searching for may no longer be available</Paragraph>
				</li>
				<li>
					<Paragraph>
						<a className='blue-text' href='mailto:hello@paireyewear.com'>
							Contact Us
						</a>{' '}
						or view our{' '}
						<a className='blue-text' href={FAQ_URL}>
							FAQ
						</a>{' '}
						for additional help
					</Paragraph>
				</li>
			</ul>
		</div>
		<PopularSearches handleSearch={handleSearch} />
	</>
);

export default memo(NoResults);
