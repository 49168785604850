import React from 'react';
import { Root, Anchor, Portal, Content, Arrow } from '@radix-ui/react-popover';
import { ComponentProps } from '@ts/components';
import { Caption } from '@components/typography';
import { Customer } from '@ts/shopify-storefront-api';
import styles from './PopupMessage.module.scss';

type PopupMessageProps = ComponentProps<HTMLElement> & {
	message: string;
	customer: Customer;
	open: boolean;
};

const PopupMessage = ({ children, message, open: isOpen }: PopupMessageProps) => {
	return (
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		<Root open={isOpen} onOpenChange={o => null}>
			<Anchor>{children}</Anchor>

			<Portal>
				<Content forceMount className={styles.container} side='bottom' sideOffset={8} collisionPadding={16}>
					<Arrow />
					<div className={styles.message}>
						<Caption>{message}</Caption>
					</div>
				</Content>
			</Portal>
		</Root>
	);
};

export default PopupMessage;
