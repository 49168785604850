import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { LOCALE_CODES } from '@constants';

const useLocale = (): UseQueryResult<string> => {
	return useQuery<string>(
		['locale'],
		async () => {
			const localeResponse = await fetch('/api/locale', {
				method: 'GET',
				headers: { 'accept': 'application/json', 'content-type': 'application/json' },
			});
			const detectedLocale = await localeResponse.json();
			
			if(!!LOCALE_CODES[detectedLocale]) {
				return LOCALE_CODES[detectedLocale];
			}

			return null;
		}
	);
};

export default useLocale;
