import {
	CleanedBuildFlowCollection,
	CleanedCollection,
	NormalizedBuildFlowCollection,
	NormalizedVariant,
	NormalizedCollection,
	NormalizedProduct,
} from '@ts/product';
import { getShopifyIdFromGID } from '@utils/shopify';
import { normalizeCollectionProduct, normalizeMetafields, normalizeProduct } from './normalize-product';

export function normalizeSingleCollection({
	id,
	title,
	handle,
	image,
	products,
	seo = null,
	...rest
}: CleanedCollection): NormalizedCollection {
	const normalizedCollection = { id, title, handle, image };
	let normalizedProducts: NormalizedProduct[] = [];

	if (!!products) {
		normalizedProducts = products.reduce((accumulator, currentValue) => {
			const np = normalizeProduct(currentValue);
			accumulator.push(np);
			return accumulator;
		}, [] as NormalizedProduct[]);
	}

	return {
		...normalizedCollection,
		id: getShopifyIdFromGID(id),
		name: title,
		products: normalizedProducts,
		seo,
		metafields: normalizeMetafields(rest),
	};
}

export function normalizeCollections(collections: CleanedCollection[]): NormalizedCollection[] {
	return collections.map(collection => normalizeSingleCollection(collection));
}

export function normalizeCollectionsByFrame(collections: CleanedBuildFlowCollection[]): NormalizedBuildFlowCollection[] {
	if (!collections || !collections.length) return null;

	return collections.map(({ id, handle, title, image, products, ...rest }) => {
		const normalizedProducts = products.reduce((accumulator, product) => {
			if (!product.variantBySelectedOptions) return accumulator;

			const np = normalizeCollectionProduct(product);
			accumulator.push(np);
			return accumulator;
		}, [] as NormalizedVariant[]);

		return {
			handle,
			image,
			title,
			id: getShopifyIdFromGID(id),
			name: title,
			metafields: normalizeMetafields(rest),
			products: normalizedProducts,
		};
	});
}

export function normalizeActiveCollections(collections): Record<string, NormalizedCollection> {
	return collections.reduce((accumulator, { id, title, handle, image, ...rest }: Omit<NormalizedCollection, 'products'>) => {
		const normalized: Omit<NormalizedCollection, 'products'> = {
			title,
			handle,
			image,
			id: getShopifyIdFromGID(id),
			metafields: normalizeMetafields(rest),
		};
		accumulator[normalized.metafields.lozengeKey] = normalized;
		return accumulator;
	}, {});
}
