import React from 'react';
import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

interface AUFlagProps {
	width?: number;
	height?: number;
}

const AUFlag = ({ width=32, height=32 }: AUFlagProps) => {
	return (
		<AccessibleIcon.Root label='AUFlag'>
			<svg width={width} height={height} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<g clip-path='url(#clip0_16715_20334)'>
					<path d='M32 16C32 24.8365 24.8365 32 16 32C7.1635 32 0 24.8365 0 16C0 16.0037 16 0.0018125 16 0C24.8365 0 32 7.1635 32 16Z' fill='#0052B4'/>
					<path d='M15.9998 0C15.9989 0 15.9979 6.25e-05 15.9971 6.25e-05L15.9998 0Z' fill='#F0F0F0'/>
					<path d='M15.957 16H15.9998C15.9998 15.9855 15.9998 15.9717 15.9998 15.9572C15.9856 15.9715 15.9713 15.9858 15.957 16Z' fill='#F0F0F0'/>
					<path d='M16 8.34787C16 5.53256 16 3.68775 16 6.10352e-05H15.9973C7.162 0.00149854 0 7.16431 0 16H8.34781V11.2992L13.0486 16H15.9572C15.9716 15.9857 15.9858 15.9715 16.0001 15.9572C16.0001 14.8792 16.0001 13.9174 16.0001 13.0487L11.2992 8.34787H16Z' fill='#F0F0F0'/>
					<path d='M8.09466 2.08691C5.59223 3.51185 3.51185 5.59223 2.08691 8.09466V16H6.26085V6.26091V6.26085H16C16 4.94441 16 3.69029 16 2.08691H8.09466Z' fill='#D80027'/>
					<path d='M15.9998 14.0324L10.3152 8.34793H8.34766C8.34766 8.34787 8.34766 8.34793 8.34766 8.34793L15.9997 16H15.9998C15.9998 16 15.9998 14.6434 15.9998 14.0324Z' fill='#D80027'/>
					<path d='M9.64972 18.7826L10.5278 20.6187L12.5108 20.1604L11.6228 21.9918L13.2174 23.2563L11.232 23.7038L11.2375 25.7391L9.64972 24.4658L8.06197 25.7391L8.06753 23.7038L6.08203 23.2563L7.67672 21.9918L6.78859 20.1604L8.77166 20.6187L9.64972 18.7826Z' fill='#F0F0F0'/>
					<path d='M23.9552 22.2609L24.3943 23.1789L25.3858 22.9497L24.9417 23.8654L25.7391 24.4977L24.7463 24.7215L24.7491 25.7391L23.9552 25.1024L23.1613 25.7391L23.1641 24.7215L22.1714 24.4977L22.9687 23.8654L22.5247 22.9497L23.5161 23.1789L23.9552 22.2609Z' fill='#F0F0F0'/>
					<path d='M19.8708 12.5217L20.3098 13.4399L21.3013 13.2106L20.8573 14.1263L21.6546 14.7586L20.6619 14.9824L20.6646 16L19.8708 15.3633L19.0769 16L19.0796 14.9824L18.0869 14.7586L18.8842 14.1263L18.4402 13.2106L19.4317 13.4399L19.8708 12.5217Z' fill='#F0F0F0'/>
					<path d='M23.9552 6.95648L24.3943 7.87461L25.3858 7.64542L24.9418 8.56111L25.739 9.19336L24.7463 9.41717L24.7491 10.4348L23.9552 9.79811L23.1613 10.4348L23.1641 9.41717L22.1714 9.19336L22.9686 8.56111L22.5247 7.64542L23.5161 7.87461L23.9552 6.95648Z' fill='#F0F0F0'/>
					<path d='M27.5231 11.1304L27.9621 12.0486L28.9536 11.8193L28.5096 12.735L29.3069 13.3673L28.3142 13.5911L28.3169 14.6087L27.5231 13.9721L26.7293 14.6087L26.7319 13.5911L25.7393 13.3673L26.5366 12.735L26.0926 11.8193L27.084 12.0486L27.5231 11.1304Z' fill='#F0F0F0'/>
					<path d='M24.9717 16L25.317 17.0629H26.4347L25.5305 17.7198L25.8759 18.7826L24.9717 18.1257L24.0676 18.7826L24.4129 17.7198L23.5088 17.0629H24.6264L24.9717 16Z' fill='#F0F0F0'/>
				</g>
			</svg>
		</AccessibleIcon.Root>
	);
};

export default AUFlag;

