import * as LabelPrimitive from '@radix-ui/react-label';
import { Paragraph as LabelText } from '@components/typography';

const Label = ({ children, input }) => {
	return (
		<LabelPrimitive.Root htmlFor={input} asChild>
			<LabelText style={{ display: 'flex', alignItems: 'center', gap: '0.8rem' }}>{children}</LabelText>
		</LabelPrimitive.Root>
	);
};

export default Label;
