import { UseRefinementListProps } from 'react-instantsearch';
import { NormalizedCollection } from '@ts/product';
import { BASE_FRAME_NAMES, PRODUCT_TYPES, PRODUCT_TYPE_NAMES } from '.';
import type { CurrentRefinementsRenderState } from 'instantsearch.js/es/connectors/current-refinements/connectCurrentRefinements';

// --- Types --- //
export type RefinementItem = {
	value: string;
	label: string;
	highlighted?: string;
	count: number;
	isRefined: boolean;
};
export type RefinementOptions = 'color' | 'design' | 'collections' | 'product_type';
export type RefinementListProps = UseRefinementListProps & {
	type: RefinementOptions;
	isOpened?: boolean;
	label?: string;
	listType?: 'accordion' | 'dropdown';
	className?: string;
	setIsDisabled?: (isDisabled: boolean) => void;
};
export type NormalizeRefinementItemsArgs = {
	type: RefinementOptions;
	collections: Record<string, Omit<NormalizedCollection, 'products'>>;
	items: Array<RefinementItem>;
	isSunglasses?: boolean;
};

// --- Constants --- //
export const NON_STANDARDIZED_COLLECTIONS = [
	'TOP_FRAME__MARVEL2023',
	'TOP_FRAME__MARVEL2023_SHE_HULK',
	'TOP_FRAME__MARVEL_GUARDIANS',
	'TOP_FRAME__MARVEL_INFINITY',
	'TOP_FRAME__SPARKLEANDSUNTOP2023',
];

export const MARVEL_COLLECTION_LABEL = 'TOP_FRAME__MARVEL';
export const MARVEL_COLLECTIONS = [
	'TOP_FRAME__MARVEL',
	'TOP_FRAME__MARVEL2023',
	'TOP_FRAME__MARVEL2023_SHE_HULK',
	'TOP_FRAME__MARVEL_GUARDIANS',
	'TOP_FRAME__MARVEL_INFINITY',
];
export const SPARKLE_COLLECTION_LABEL = 'TOP_FRAME__SPARKLE';
export const SPARKLE_COLLECTIONS = ['TOP_FRAME__SPARKLE', 'TOP_FRAME__SPARKLEANDSUNTOP2023'];

export const ALGOLIA_DEFAULT_INDEX = 'shopify_products';

export const ALGOLIA_SEARCH_INDEXES = {
	US: {
		RECENTLY_ORDERED: `${ALGOLIA_DEFAULT_INDEX}_recently_ordered_count_desc`,
		SHOPIFY_PRODUCTS: ALGOLIA_DEFAULT_INDEX,
		BUILDFLOW_FILTERING: 'buildflow_filtering', //Note: Leave for future merchandising use
	},
	CA: {
		RECENTLY_ORDERED: `${ALGOLIA_DEFAULT_INDEX}_canada_en_recently_ordered_count_desc`,
		SHOPIFY_PRODUCTS: `${ALGOLIA_DEFAULT_INDEX}_canada_en`,
		BUILDFLOW_FILTERING: 'buildflow_filtering', //Note: Leave for future merchandising use
	},
	AU: {
		RECENTLY_ORDERED: `${ALGOLIA_DEFAULT_INDEX}_au_en_recently_ordered_count_desc`,
		SHOPIFY_PRODUCTS: `${ALGOLIA_DEFAULT_INDEX}_au_en`,
		BUILDFLOW_FILTERING: 'buildflow_filtering', //Note: Leave for future merchandising use
	},
	GB: {
		RECENTLY_ORDERED: `${ALGOLIA_DEFAULT_INDEX}_gb_en_recently_ordered_count_desc`,
		SHOPIFY_PRODUCTS: `${ALGOLIA_DEFAULT_INDEX}_gb_en`,
		BUILDFLOW_FILTERING: 'buildflow_filtering', //Note: Leave for future merchandising use
	},
};

export const ALGOLIA_SEARCH_PARAMS = {
	US: {
		ALGOLIA_SEARCH_PARAM: `${ALGOLIA_SEARCH_INDEXES.US.RECENTLY_ORDERED}[query]`,
		ALGOLIA_SEARCH_URL: `search?${ALGOLIA_SEARCH_INDEXES.US.RECENTLY_ORDERED}%5Bquery%5D=`,
		ALGOLIA_FILTER_COLOR_URL: `${ALGOLIA_SEARCH_INDEXES.US.RECENTLY_ORDERED}[refinementList][meta.custom_fields.colors][0]`,
		ALGOLIA_FILTER_DESIGN_URL: `${ALGOLIA_SEARCH_INDEXES.US.RECENTLY_ORDERED}[refinementList][meta.custom_fields.design][0]`,
	},
	CA: {
		ALGOLIA_SEARCH_PARAM: `${ALGOLIA_SEARCH_INDEXES.CA.RECENTLY_ORDERED}[query]`,
		ALGOLIA_SEARCH_URL: `search?${ALGOLIA_SEARCH_INDEXES.CA.RECENTLY_ORDERED}%5Bquery%5D=`,
		ALGOLIA_FILTER_COLOR_URL: `${ALGOLIA_SEARCH_INDEXES.CA.RECENTLY_ORDERED}[refinementList][meta.custom_fields.colors][0]`,
		ALGOLIA_FILTER_DESIGN_URL: `${ALGOLIA_SEARCH_INDEXES.CA.RECENTLY_ORDERED}[refinementList][meta.custom_fields.design][0]`,
	},
	AU: {
		ALGOLIA_SEARCH_PARAM: `${ALGOLIA_SEARCH_INDEXES.AU.RECENTLY_ORDERED}[query]`,
		ALGOLIA_SEARCH_URL: `search?${ALGOLIA_SEARCH_INDEXES.AU.RECENTLY_ORDERED}%5Bquery%5D=`,
		ALGOLIA_FILTER_COLOR_URL: `${ALGOLIA_SEARCH_INDEXES.AU.RECENTLY_ORDERED}[refinementList][meta.custom_fields.colors][0]`,
		ALGOLIA_FILTER_DESIGN_URL: `${ALGOLIA_SEARCH_INDEXES.AU.RECENTLY_ORDERED}[refinementList][meta.custom_fields.design][0]`,
	},
	GB: {
		ALGOLIA_SEARCH_PARAM: `${ALGOLIA_SEARCH_INDEXES.GB.RECENTLY_ORDERED}[query]`,
		ALGOLIA_SEARCH_URL: `search?${ALGOLIA_SEARCH_INDEXES.GB.RECENTLY_ORDERED}%5Bquery%5D=`,
		ALGOLIA_FILTER_COLOR_URL: `${ALGOLIA_SEARCH_INDEXES.GB.RECENTLY_ORDERED}[refinementList][meta.custom_fields.colors][0]`,
		ALGOLIA_FILTER_DESIGN_URL: `${ALGOLIA_SEARCH_INDEXES.GB.RECENTLY_ORDERED}[refinementList][meta.custom_fields.design][0]`,
	},
};

export const ALGOLIA_REFINEMENTS_SEARCH: Array<RefinementListProps> = [
	{
		type: 'product_type',
		label: 'Product Type',
		attribute: 'product_type',
		operator: 'or',
		limit: 3,
		showMore: true,
		showMoreLimit: 30,
	},
	{
		type: 'color',
		label: 'Color',
		attribute: 'meta.custom_fields.colors',
		operator: 'or',
		limit: 20,
	},
	{
		type: 'design',
		attribute: 'meta.custom_fields.design',
		label: 'Design',
		operator: 'or',
		limit: 4,
		showMore: true,
		showMoreLimit: 20,
	},
	{
		type: 'collections',
		label: 'Collection',
		attribute: 'product_type',
		operator: 'or',
		limit: 4,
		showMore: true,
		showMoreLimit: 30,
	},
];

export const ALGOLIA_REFINEMENTS_ALLTOPS: Array<RefinementListProps> = [
	{
		type: 'color',
		label: 'Color',
		attribute: 'meta.custom_fields.colors',
		operator: 'or',
		limit: 20,
	},
	{
		type: 'design',
		attribute: 'meta.custom_fields.design',
		label: 'Design',
		operator: 'or',
		limit: 4,
		showMore: true,
		showMoreLimit: 20,
	},
	{
		type: 'collections',
		label: 'Collection',
		attribute: 'product_type',
		operator: 'or',
		limit: 4,
		showMore: true,
		showMoreLimit: 30,
	},
];

export const ALGOLIA_REFINEMENTS_BUILDFLOW: Array<RefinementListProps> = [
	{
		type: 'color',
		label: 'Color',
		attribute: 'meta.custom_fields.colors',
		operator: 'or',
		limit: 20,
	},
	{
		type: 'design',
		attribute: 'meta.custom_fields.design',
		label: 'Design',
		operator: 'or',
		limit: 25,
	},
	{
		type: 'collections',
		label: 'Collection',
		attribute: 'product_type',
		operator: 'or',
		limit: 25,
	},
];

// --- Functions --- //
export const getMarvelRefinements = (items: Array<RefinementItem>) =>
	items.filter(item => MARVEL_COLLECTIONS.includes(item.value));
export const getSparkleRefinements = (items: Array<RefinementItem>) =>
	items.filter(item => SPARKLE_COLLECTIONS.includes(item.value));
export const filterRefinements = items => items.filter(item => !NON_STANDARDIZED_COLLECTIONS.includes(item.label));
export const normalizeCollectionRefinement = (
	activeCollections: Record<string, Omit<NormalizedCollection, 'products'>>,
	label: string
) => {
	if (PRODUCT_TYPE_NAMES[label]) {
		return PRODUCT_TYPE_NAMES[label];
	}
	if (activeCollections[label]) {
		return activeCollections[label].title;
	} else {
		return label.replace(/TOP_FRAME__/g, '');
	}
};

export function getAttributeRefinements(attribute: string, items: CurrentRefinementsRenderState['items']) {
	const item = items.find(item => item.attribute === attribute);
	return item?.refinements || [];
}

/**
 * transformItems
 *
 * @description Filters Algolia refinement items to remove non-standardized collections
 * @param items
 * @returns
 */
export const transformItems = (items: Array<RefinementItem>): Array<RefinementItem> => {
	return items.filter(item => !NON_STANDARDIZED_COLLECTIONS.includes(item.label));
};

/**
 * normalizeRefinementItems
 *
 * @description Normalizes Algolia refinement items for use in refinement option components: <RefinementSwatch /> and <RefinementOption />
 */
export const normalizeRefinementItems = ({ type, collections, items, isSunglasses = false }: NormalizeRefinementItemsArgs) => {
	const itemsMap = new Map();
	items.forEach(item => {
		const { label, value, isRefined } = item;
		let formattedLabel = '';
		if (type === 'color' || type === 'design') {
			formattedLabel = label.replace(/\["|"\]+/g, '');
		} else if (type === 'collections') {
			formattedLabel = collections && normalizeLabel({ collections, label, items });
			if (isSunglasses && label.includes('SUNTOP')) return;
		} else if (type === 'product_type') {
			formattedLabel = normalizeProductType(label, items);
		}
		itemsMap.set(formattedLabel, { label, value, isRefined });
	});
	return itemsMap;
};

export const normalizeLabel = ({ collections, label, items }) => {
	const hasProductType = items.some(
		item => (item.label.includes(PRODUCT_TYPES.ACCESSORY) || item.label.includes(PRODUCT_TYPES.BASE_FRAME)) && item.isRefined
	);
	if (collections[label] && !hasProductType) {
		return collections[label].title;
	}
};

const normalizeProductType = (label: string, items: Array<RefinementItem>) => {
	if(label === 'frame'){
		return PRODUCT_TYPE_NAMES[PRODUCT_TYPES.TOP_FRAME];
	}
	const hasTopFrames = items.some(item => item.label.includes(PRODUCT_TYPES.TOP_FRAME) && item.isRefined);
	const keys = Object.values(PRODUCT_TYPES) as string[];
	if (keys.includes(label) && !hasTopFrames) {
		return PRODUCT_TYPE_NAMES[label];
	}
};

export const validateQueryBaseFrame = (query: string) => {
	return new RegExp([BASE_FRAME_NAMES, ...BASE_FRAME_NAMES.map(name => name.toLowerCase())].join('|')).test(query);
};
