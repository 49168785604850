import { NormalizedProduct } from '@ts/product';
import { PRODUCT_TYPES } from "@constants";

export type ProductJsonLdProps = NormalizedProduct & {
	aggregateRating?: { bottomline: { average_score: number; total_review: number } };
	suggestedGender?: string;
}

export function addProductJsonLd(product: ProductJsonLdProps) {
	if (!product) return { __html: '' };
	const { aggregateRating = false, name, description, price, images, suggestedGender, type, variants } = product;

	const hasRating =
		aggregateRating && aggregateRating.bottomline?.total_review > 0 && aggregateRating.bottomline?.average_score > 0;

	let gender = 'Unisex';
	let minAge = 5;
	let maxAge = 13;
	if (suggestedGender === 'women') {
		gender = 'Female';
		minAge = 13;
		maxAge = 99;
	}
	if (suggestedGender === 'men') {
		gender = 'Male';
		minAge = 13;
		maxAge = 99;
	}

	const __html = {
		'@context': 'https://schema.org/',
		'@type': 'Product',
		'name': name,
		'image': images.map(img => img?.url),
		'description': description,
		'sku': variants[0]?.sku,
		'price': price.amount,
		'priceCurrency': price.currencyCode,
		'suggestedGender': gender,
		...(type.includes(PRODUCT_TYPES.BASE_FRAME) && gender === 'Unisex' ? { suggestedMaxAge: maxAge } : {}),
		...(type.includes(PRODUCT_TYPES.BASE_FRAME) ? { suggestedMinAge: minAge } : {}),
		...(hasRating
			? {
				aggregateRating: {
					'@type': 'AggregateRating',
					'ratingValue': aggregateRating.bottomline?.average_score.toFixed(2) ?? '5',
					'reviewCount': aggregateRating.bottomline?.total_review.toString() ?? '5',
				},
			}
			: {
				aggregateRating: {
					'@type': 'AggregateRating',
					'ratingValue': '5',
					'reviewCount': '5',
				},
			}),
	};

	return {
		__html: JSON.stringify(__html),
	};
}

export type ArticleJsonLdProps = {
	headline: string;
	description: string;
	image: Array<string>;
	url: string;
	datePublished: string;
}

export function addArticleJsonLd(article) {
	const __html = {
		'@context': 'http://schema.org',
		'@type': 'NewsArticle',
		"headline": article.headline,
		"image": article.image,
		"datePublished": article.datePublished,
		"dateModified": article.datePublished,
		"author":
			[
				{
					"@type": "Organization",
					"name": "Pair Eyewear",
					"url": "https://www.paireyewear.com/",
				},
			],
	}

	return {
		__html: JSON.stringify(__html),
	}
}
