const cartCreateMutation = /* GraphQL */ `
	mutation cartCreate (
		$country: CountryCode = US
	) @inContext(country: $country){
		cartCreate {
			cart {
				id
			}
			userErrors {
				code
				field
				message
			}
		}
	}
`;

export { cartCreateMutation };
