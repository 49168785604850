/* eslint-disable @typescript-eslint/no-explicit-any */
import { CleanedCollection, NormalizedBuildFlowCollection, NormalizedCollection } from '@ts/product';
import { getShopifyIdFromGID } from '@utils/index';
import { normalizeActiveCollections, normalizeCollections } from '@utils/normalizers';

// ONLY use this function for Active Collections; wil be refactored once data type is changed
export function sortByContentfulOrder(collections: NormalizedCollection[], map) {
	const sortedMap = { ...map };
	collections.forEach(collection => {
		const id = getShopifyIdFromGID(collection.id);
		sortedMap[id] = collection;
	});

	// Contentful Guard for null Collections
	for (const [key, value] of Object.entries(sortedMap)) {
		if (typeof value !== 'object' && value !== null) {
			delete sortedMap[key];
		}
	}

	const collectionsMap = { ...sortedMap };
	Object.values(collectionsMap).forEach((value: NormalizedCollection) => {
		delete collectionsMap[value.id];
		collectionsMap[value.metafields.lozengeKey] = value;
	});

	return collectionsMap;
}

// TODO: Combine with sortByContentfulOrder()
// Use this version, not the one above
export function sortCollectionOrderFromContentful({
	collections,
	map,
	orderBy = 'id',
}: {
	collections: NormalizedCollection[] | NormalizedBuildFlowCollection[];
	// map: { [k: number]: NormalizedBuildFlowCollection };
	map: Record<string, any>; //TODO: Fix type
	orderBy?: 'id' | 'title';
}) {
	if (!collections || !collections.length) return null;
	
	const copiedMap = { ...map };
	collections.forEach(collection => {
		copiedMap[collection[orderBy]] = collection;
	});

	// Contentful Guard for null Collections
	for (const [key, value] of Object.entries(copiedMap)) {
		if (typeof value !== 'object' && value !== null) {
			delete copiedMap[key];
		}
	}

	return Object.values(copiedMap);
}

export function transformActiveCollections([collections, map]): NormalizedCollection[] {
	const normalized = normalizeActiveCollections(collections);
	return sortByContentfulOrder(Object.values(normalized), map);
}

export function transformCollections([collections, map]): NormalizedCollection[] {
	const normalized = normalizeCollections(collections as CleanedCollection[]);
	return sortCollectionOrderFromContentful({ collections: normalized, map });
}
