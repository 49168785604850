import { ReactNode, createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { usePagination, useRefinementList, useSearchBox } from 'react-instantsearch';
import { useRouter } from 'next/router';
import { BASE_FRAME_NAMES, LARGE_WIDTH } from '@utils/constants';
import { useIsMobile, useLocalStorage } from '@utils/hooks';
import { useCollectionsQuery } from '@utils/react-query/collections/useCollectionsQuery';
import { LOCALE_DICT, validateQueryBaseFrame } from '..';

const FilterContext = createContext(null);

export const FilterProvider = ({ collectionList, frameShapeOverride = undefined, isSunglassesRoute = false, type, children }: {
	collectionList: string;
	frameShapeOverride?: typeof BASE_FRAME_NAMES[number];
	isSunglassesRoute?: boolean;
	type: 'all-tops' | 'buildflow' | 'search' | 'bf-all-tops';
	children: ReactNode
}) => {
	const isBrowser = () => typeof window !== 'undefined';
	const virtuosoRef = useRef(null);
	const [activeFrame] = useLocalStorage<typeof BASE_FRAME_NAMES[number]>('activeFrame', 'Larkin');
	const isMobile = useIsMobile({ maxWidth: LARGE_WIDTH });
	const { refine } = usePagination();
	const { locale } = useRouter();
	const countryCode = LOCALE_DICT[locale].countryCode;

	const [frameShape, setFrameShape] = useState(frameShapeOverride || activeFrame);
	const [isOpen, setIsOpen] = useState(!isMobile && type !== 'bf-all-tops');
	const [showFrameSelector, setShowFrameSelector] = useState(true);
	const [isHeaderBigger, setIsHeaderBigger] = useState(true);

	const { data: collections } = useCollectionsQuery(collectionList, {
		queryKey: 'active-collections',
		type: 'active',
		withProducts: false,
		country: countryCode,
		queryRefreshVars: [locale],
	});

	const { items: frameOptions } = useRefinementList({ attribute: 'options.frame' });
	const { query } = useSearchBox();

	useEffect(() => {
		const isValidatedQueryBaseFrame = validateQueryBaseFrame(query);
		setShowFrameSelector(frameOptions.length > 0 && !isValidatedQueryBaseFrame);
	}, [frameOptions, query]);

	const updateFrameShape = useCallback((frameShape: typeof BASE_FRAME_NAMES[number]) => {
		setFrameShape(frameShape);
		refine(0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [frameShape]);

	const scrollToTop = useCallback(() => {
		if (!isBrowser()) return;
		virtuosoRef.current.scrollToIndex({ index: 0, behavior: 'smooth' });
		window.scrollTo(0, 0);
	}, []);

	return <FilterContext.Provider value={{
		collections,
		frameShape,
		isSunglassesRoute,
		isOpen,
		isMobile,
		type,
		virtuosoRef,
		showFrameSelector,
		setFrameShape,
		setIsOpen,
		scrollToTop,
		updateFrameShape,
		isHeaderBigger,
		setIsHeaderBigger,
	}}>
		{children}
	</FilterContext.Provider>;
};

export function useFilterContext() {
	return useContext(FilterContext);
}
